exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-finitions-interieures-tsx": () => import("./../../../src/pages/finitions-interieures.tsx" /* webpackChunkName: "component---src-pages-finitions-interieures-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installation-et-reparation-tsx": () => import("./../../../src/pages/installation-et-reparation.tsx" /* webpackChunkName: "component---src-pages-installation-et-reparation-tsx" */),
  "component---src-pages-sablage-de-plancher-tsx": () => import("./../../../src/pages/sablage-de-plancher.tsx" /* webpackChunkName: "component---src-pages-sablage-de-plancher-tsx" */),
  "component---src-pages-teinture-et-vernissage-tsx": () => import("./../../../src/pages/teinture-et-vernissage.tsx" /* webpackChunkName: "component---src-pages-teinture-et-vernissage-tsx" */)
}

